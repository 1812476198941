*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  word-break: break-all;
  white-space: break-spaces;
}
